<template>
    <div>
        <b-card title="Gestion des codes postaux">
            <b-col cols="3">
                <b-form-group label="Sélectionner une marque" label-for="input-select-brand">
                    <b-form-select id="input-select-brand" v-model="selectedBrand" :options="brands" @change="fetchBrands"></b-form-select>
                </b-form-group>
                <b-button size="sm" variant="primary" @click="downloadReport(selectedBrand)"> Télecharger </b-button>
            </b-col>

            <pos-table :brand="selectedBrand" :posInfo="posInfo" :subByPass="subByPass" @on-update-name-brand="(data) => updateNamePrice(data)"></pos-table>
        </b-card>
    </div>
</template>

<script>
    import { mapActions } from 'vuex';
    import PosTable from './pos-table.vue';
    import { mapGetters } from 'vuex';
    import FileSaver from 'file-saver';

    export default {
        components: {
            PosTable,
        },
        data() {
            return {
                brands: [],
                brand: '',
                posInfo: [],
                selectedBrand: 'citroen',
                subByPass: false,
            };
        },
        mounted() {
            this.init();
        },
        computed: {
            ...mapGetters({
                successToast: 'app/successToast',
                errorToast: 'app/errorToast',
            }),
        },
        methods: {
            ...mapActions({
                getBrands: 'app/getBrands',
                getPosOfInterest: 'app/getPosOfInterest',
                getConfigParams: 'app/getConfigParams',
            }),
            init() {
                this.$root.toggleGlobalLoading(false);
                let promises = [this.fetchBrands()];
                Promise.all(promises)
                    .then((res) => {
                        this.$root.toggleGlobalLoading(false);
                    })
                    .catch((e) => {
                        console.error(e);
                        this.$toast(this.errorToast('Erreur lors de la récupération des données'));
                    });
            },
            fetchBrands() {
                this.$set(this, 'brand', this.selectedBrand);
                return this.getBrands().then((res) => {
                    const brands = res.data.map((b) => b.name);
                    this.$set(this, 'brands', brands);
                    this.fetchPosInfo();
                });
            },
            async fetchPosInfo() {
                return this.getPosOfInterest(this.selectedBrand).then((res) => {
                    this.$set(this, 'posInfo', res.data);
                });
            },
            downloadReport(selectedBrand) {
                this.$root.toggleGlobalLoading(true);
                let pointCode = this.posInfo.map((obj) => obj.point_code);
                let payload = {
                    point_code: pointCode,
                    brand: selectedBrand,
                };
                this.$store
                    .dispatch('app/getAllZipcode', payload)
                    .then((res) => {
                        let file = res.data;
                        FileSaver.saveAs(file, `report.xlsx`);
                        this.$root.toggleGlobalLoading(false);
                        this.$toast({
                            component: 'toast',
                            props: {
                                title: 'Le rapport a été téléchargé',
                                icon: 'smile',
                                variant: 'success',
                            },
                        });
                    })
                    .catch((e) => {
                        console.error(e);
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Connection erreur',
                                icon: 'x-circle',
                                variant: 'danger',
                            },
                        });
                    });
            },
        },
    };
</script>

<style lang="scss"></style>
