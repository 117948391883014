<template>
    <b-card>
        <b-input-group class="mb-1 w-25 d-flex ml-auto">
            <b-input-group-prepend>
                <b-input-group-text>
                    <feather-icon icon="SearchIcon"></feather-icon>
                </b-input-group-text>
            </b-input-group-prepend>
            <b-input v-model="filter"></b-input>
        </b-input-group>

        <b-table hover striped responsive ref="table" :filter="filter" :items="codeForTable" :fields="fields"
            :per-page="perPage" :current-page="currentPage" @row-clicked="openModal" @filtered="onTableFiltered">
        </b-table>
        <b-pagination class="mt-3" v-model="currentPage" :total-rows="rows" :per-page="perPage"
            first-number></b-pagination>
        <copyZipCodeModal v-if="showModal" :modalTitle="modalTitle" :zipcode="zipcode"
            :selectedFormat.sync="selectedFormat" @show-toast="handleToast" @close="showModal = false" :brand="brand"
            :pointCode="pointCode" :mediaDetails="{}" />
    </b-card>
</template>

<script>
import { mapGetters } from 'vuex';
import copyZipCodeModal from '../../components/copyZipCodeModal.vue';

export default {
    components: {
        copyZipCodeModal,
    },
    props: {
        posInfo: {
            type: Array,
            required: true,
        },
        brand: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            zipcode: [],
            selectedFormat: 'comma',
            selectedPos: {},
            showModal: false,
            modalTitle: 'Details Geographiques',
            fields: [
                { key: 'point_code', label: 'Code point de vente' },
                { key: 'details.name', label: 'Nom' },
                { key: 'details.city', label: 'Nom de la ville' },
                { key: 'details.zipcode', label: 'Code Postal' },
            ],
            rows: 0,
            perPage: 10,
            currentPage: 1,
            filter: '',
            pointCode: '',
        };
    },
    computed: {
        ...mapGetters({
            successToast: 'app/successToast',
            errorToast: 'app/errorToast',
        }),
        codeForTable() {
            let data = this.posInfo.map((banner) => {
                return {
                    ...banner,
                    brandName: banner.point_code,
                    sourceName: banner.details.name,
                };
            });

            if (this.filter === '') this.setRows(data);
            return data;
        },
    },
    watch: {},
    methods: {
        setRows(list) {
            this.rows = list.length;
        },
        onTableFiltered(list) {
            this.setRows(list);
        },
        async openModal(item) {
            this.pointCode = item.point_code;
            this.showModal = true;
        },
        handleToast({ type, message }) {
            this.$toast(this[type + 'Toast'](message));
        },
    },
};
</script>

<style scoped lang="scss">
td {
    height: auto !important;
}

#code-to-copy {
    margin-top: 10px;
}

.copyContainer {
    display: flex;
    justify-content: space-between;
}

.zipcode-list {
    padding: 10px;
    overflow-x: hidden;
}
</style>
